/* global FB */

import axios from "axios";
import store from "store";
import { deleteField, doc, updateDoc } from "firebase/firestore";

// Functions
import {
  WriteConnectionErrorToFirebase,
  addPlatformService,
  isOnline,
  sweetAlert,
  updatePlatformService,
  updatePlatformServices,
} from "./global";
import { setFbAdsAccounts } from "actions/app";

// Constants
import { USER_DATA_AND_CHATBOT_COLLECTION } from "constants/global/firebase-collections";
import { ApiServerPath } from "api_server";

export const facebookGraphInit = async () => {
  try {
    await addFacebookScript();
    FB.init({ version: "v19.0" });
  } catch (error) {
    console.log(error);
  }
};

const addScript = (id: any, src: any) =>
  new Promise((resolve, reject) => {
    const element = document.getElementById(id);

    if (element) {
      return resolve(true);
    }

    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", id);
    script.setAttribute("src", src);
    script.addEventListener("load", resolve);
    script.addEventListener("error", () =>
      reject(new Error(`Error loading ${id}.`))
    );
    script.addEventListener("abort", () =>
      reject(new Error(`${id}  loading aborted.`))
    );
    document.getElementsByTagName("head")[0].appendChild(script);
  });

const addFacebookScript = () => {
  const id = "facebookAuth";
  const src =
    "https://connect.facebook.net/en_US/sdk.js#version=v19.0&appId=380710515915761&status=true&cookie=true&xfbml=true";

  return addScript(id, src);
};

export const fbGraphApi = (
  url?: any,
  access_token?: any,
  fields?: any,
  httpMethod: any = "GET",
  file_url?: any,
  limit?: any
) => {
  return new Promise((resolve, _reject) => {
    try {
      const { platformServices = {} }: any = store.getState().appReducer;
      const { facebookAds = {} } = platformServices;
      const { refreshToken: accessToken } = facebookAds;

      FB.api(
        url,
        httpMethod,
        {
          limit,
          fields,
          file_url,
          access_token: access_token || accessToken,
        },
        (response: any) => {
          resolve(response);
        }
      );
    } catch (error) {
      console.log(error);
    }
  });
};

export const getLongLiveToken = (access_token: any) => {
  return new Promise((resolve, reject) => {
    try {
      FB.api(
        "oauth/access_token",
        {
          grant_type: "fb_exchange_token",
          fb_exchange_token: access_token, // USER_TOKEN
          client_id: process.env.REACT_APP_FB_APP_ID, // FB_APP_ID
          client_secret: process.env.REACT_APP_FB_CLIENT_SECRET, // FB_APP_SECRET
        },
        (res: any) => {
          if (!res || res?.error) {
            reject(res?.error);
          } else {
            const accessToken = res.access_token;
            if (typeof accessToken != "undefined") {
              resolve(accessToken);
            }
          }
        }
      );
    } catch (error) {
      WriteConnectionErrorToFirebase(error, "Facebook accessToken");
    }
  });
};

export const getFbProfile = async (
  accessToken: any,
  connectIdFlow?: boolean
) => {
  return new Promise(async (resolve, reject) => {
    const response = await fbGraphApi("me", accessToken, "id,name");
    if ((response as any).error) {
      reject((response as any).error);
    } else {
      if (!connectIdFlow) {
        updatePlatformServices("facebookAds.userName", (response as any)?.name);
      }
      resolve(response as any);
    }
  });
};

export const fbAdsLogin = () => {
  if (isOnline()) {
    try {
      window.FB.login(
        async (response: any) => {
          WriteConnectionErrorToFirebase(response, "Facebook");
          if (response.authResponse) {
            const { userData = {} }: any = store.getState().appReducer;
            const { lastSelectedWorkspace = {} } = userData;
            const { workspaceId } = lastSelectedWorkspace;

            const longLiveAccessToken: any = await getLongLiveToken(
              response?.authResponse?.accessToken
            );

            await addPlatformService(
              "facebookAds",
              longLiveAccessToken,
              workspaceId
            );

            const docRef = doc(USER_DATA_AND_CHATBOT_COLLECTION, workspaceId);
            updateDoc(docRef, {
              pixelId: deleteField(),
              conversionGoal: deleteField(),
              lastSelectedFbPage: deleteField(),
              lastSelectedInstaAcc: deleteField(),
            });

            await getFbProfile(longLiveAccessToken || "");
            const fbAccounts = await getFbAdsAccounts(
              "debugmessage2",
              longLiveAccessToken
            );
            const isMangerPermission =
              (fbAccounts as any)?.data?.[0]?.user_tasks &&
              (fbAccounts as any)?.data?.[0]?.user_tasks.includes("MANAGE");
            const isAdvertiserPermission =
              (fbAccounts as any)?.data?.[0]?.user_tasks &&
              (fbAccounts as any)?.data?.[0]?.user_tasks.includes("ADVERTISE");

            if (
              (fbAccounts as any)?.data?.length === 1 &&
              ((fbAccounts as any)?.data?.[0].id === "act_115263056585426" ||
                (fbAccounts as any)?.data?.[0].id === "act_427053459589047" ||
                (((fbAccounts as any)?.data?.[0].account_status !== 2 ||
                  (fbAccounts as any)?.data?.[0].account_status !== 3 ||
                  (fbAccounts as any)?.data?.[0].account_status !== 101) &&
                  (!(fbAccounts as any)?.data?.[0]?.user_tasks ||
                    (isMangerPermission
                      ? (fbAccounts as any)?.data?.[0]?.funding_source
                      : isAdvertiserPermission))))
            ) {
              updatePlatformService(
                "facebookAds.accountId",
                "facebookAds.accountName",
                (fbAccounts as any)?.data[0],
                "facebookAds.currencyCode"
              );
            }
            store.dispatch(setFbAdsAccounts((fbAccounts as any)?.data));
          }
        },
        {
          scope:
            "email,public_profile,pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_read_user_content,ads_management,read_insights,instagram_basic,leads_retrieval,business_management",
        }
      );
    } catch (error) {
      WriteConnectionErrorToFirebase(error, "Facebook");
    }
  } else {
    sweetAlert(
      "Please make sure your device connected to internet!",
      "error",
      null
    );
  }
};

export const getFbAdsAccounts = (message: string, token?: string) => {
  return new Promise(async (resolve, _reject) => {
    try {
      const { platformServices = {} }: any = store.getState().appReducer;
      const { facebookAds = {} } = platformServices;
      let { refreshToken: accessToken } = facebookAds;

      accessToken = token || accessToken;

      const response = await axios.post(
        `${ApiServerPath.API_SERVER_BASE_URL}${ApiServerPath.GET_FB_AD_ACCOUNTS}`,
        {
          accessToken,
          message,
        }
      );

      if (response?.data?.results) {
        resolve(response?.data?.results);
      } else {
        resolve({ error: response?.data?.results?.errormessage });
      }
    } catch (error) {
      resolve({ error: "Something went wrong." });
    }
  });
};

export const getFbAccountPermissions = (fromCreateIdFlow?: boolean) => {
  return new Promise(async (resolve, _reject) => {
    try {
      const response: any = await fbGraphApi(`/me`, null, "permissions");
      console.log(response, "response permissions");
      if (response) {
        resolve(response);
      }
    } catch (error) {
      console.log(error, "error in permissions");
    }
  });
};
